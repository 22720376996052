<template>
  <div class="site-container page-home">
    <ab-header></ab-header>

    <div class="site-banner">
      <img src="@/assets/img/website/banner-home.png" />
    </div>

    <div class="site-module">
      <div class="sm-hd">产品服务</div>
      <div class="sm-bd home-service">
        <div class="item">
          <div class="tit">国际运输</div>
          <div class="list">
            <div class="one">半导体材料国际航空运输</div>
            <div class="one">海运货物运输FCL&LCL</div>
            <div class="one">冷冻&危险品航空运输</div>
            <div class="one">中港卡车</div>
            <div class="one">精密设备航空运输</div>
          </div>
          <div class="more" @click="() => { this.$router.push('/service-transport-abroad') }">查看详情</div>
        </div>
        <div class="item">
          <div class="tit">国内运输</div>
          <div class="list">
            <div class="one">晶圆、IC成品运输</div>
            <div class="one">国内航空运输</div>
            <div class="one">气垫车国内运输</div>
            <div class="one">定制化方案设计</div>
            <div class="one">危险品国内运输</div>
          </div>
          <div class="more" @click="() => { this.$router.push('/service-transport-domestic') }">查看详情</div>
        </div>
        <div class="item">
          <div class="tit">仓储及清关</div>
          <div class="list">
            <div class="one">半导体恒温恒湿仓库</div>
            <div class="one">信息技术及支持</div>
            <div class="one">保税区仓储服务</div>
            <div class="one">半导体产品代理清关</div>
            <div class="one">供应商管理库存</div>
            <div class="one">商品归类服务</div>
          </div>
          <div class="more" @click="() => { this.$router.push('/service-storage-custom') }">查看详情</div>
        </div>
        <div class="item">
          <div class="tit">配套服务</div>
          <div class="list">
            <div class="one">精密设备搬运</div>
            <div class="one">集团保险覆盖</div>
            <div class="one">个性化打包、组装服务</div>
            <div class="one">海运一贯式运输</div>
            <div class="one">建厂项目管理</div>
            <div class="one">提供驻厂人员</div>
          </div>
          <div class="more" @click="() => { this.$router.push('/service-related') }">查看详情</div>
        </div>
      </div>
    </div>

    <div class="site-module">
      <div class="sm-hd">覆盖国家70+&nbsp;重点线路20条</div>
      <div class="sm-bd home-transport"></div>
    </div>

    <div class="site-module">
      <div class="sm-hd">资质荣誉</div>
      <div class="sm-bd home-honor">
        <div class="pic-list">
          <div class="item">
            <img src="@/assets/img/website/honor-1.png" />
          </div>
          <div class="item">
            <img src="@/assets/img/website/honor-2.png" />
          </div>
          <div class="item">
            <img src="@/assets/img/website/honor-3.png" />
          </div>
          <div class="item">
            <img src="@/assets/img/website/honor-4.png" />
          </div>
          <div class="item">
            <img src="@/assets/img/website/honor-5.png" />
          </div>
          <div class="item">
            <img src="@/assets/img/website/honor-6.png" />
          </div>
          <div class="item">
            <img src="@/assets/img/website/honor-7.png" />
          </div>
        </div>
        <div class="name-list">
          <div class="item">
            国家级<br />
            高新技术企业
          </div>
          <div class="item">
            全国供应链<br />
            创新与应用<br />
            示范企业
          </div>
          <div class="item">
            中国外贸出口<br />
            先导指数<br />
            样板企业
          </div>
          <div class="item">
            海关AEO<br />
            高级认证企业
          </div>
          <div class="item">
            ISO9001系列<br />
            管理体系认证
          </div>
          <div class="item">
            海关特约<br />
            监督员企业
          </div>
          <div class="item">
            中国报关协会<br />
            会员单位
          </div>
          <div class="item">
            上海市集成电路<br />
            行业协会会员
          </div>
          <div class="item">
            湖南开放强省<br />
            十强外贸企业
          </div>
          <div class="item">
            湖南省100个<br />
            重大科技创新项目
          </div>
        </div>
      </div>
    </div>

    <ab-footer></ab-footer>
  </div>
</template>

<script>
export default {
  name: 'home',
  components: {

  },

  data () {
    return {
      
    }
  },

  computed: {

  },

  watch: {

  },

  mounted() {

  },

  methods: {
    
  }
}
</script>

<style lang="scss">
.page-home {
  .home-service {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .item {
      width: calc(50% - 14px);
      height: 294px;
      margin-bottom: 28px;
      padding: 30px 40px;
      border-radius: 30px;
      background-color: #D3EDFB;
      background-position: 396px 166px;
      background-repeat: no-repeat;
      background-size: 165px 108px;
      &:nth-child(1) {
        background-image: url("../../assets/img/website/hs-1.png");
      }
      &:nth-child(2) {
        background-image: url("../../assets/img/website/hs-2.png");
      }
      &:nth-child(3) {
        background-image: url("../../assets/img/website/hs-3.png");
      }
      &:nth-child(4) {
        background-image: url("../../assets/img/website/hs-4.png");
      }
      .tit {
        font-size: 36px;
        font-weight: 700;
        color: #000;
      }
      .list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        .one {
          width: 50%;
          height: 38px;
          font-size: 20px;
          line-height: 38px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          color: #000;
        }
      }
      .more {
        width: 160px;
        height: 38px;
        margin-top: 20px;
        border: 1px solid #009FE8;
        border-radius: 6px;
        font-size: 18px;
        line-height: 36px;
        color: #009FE8;
        text-align: center;
        letter-spacing: 5px;
        cursor: pointer;
      }
    }
  }
  .home-transport {
    width: 100%;
    height: 641px;
    background: url("../../assets/img/website/transport.png") no-repeat center;
    background-size: contain;
  }
  .home-honor {
    width: 100%;
    .pic-list {
      display: flex;
      justify-content: space-between;
    }
    .name-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 70px;
      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 219px;
        height: 112px;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        text-align: center;
        color: #000;
        background: url("../../assets/img/website/honor-bg.png") no-repeat center;
        background-size: contain;
        &:nth-of-type(n+6) {
          margin-top: 50px;
        }
      }
    }
  }
}
</style>
